// 11. Global
// ==========================================================================

// Media Query Ranges
$small-screen-up: 601px !default;
$medium-screen-up: 993px !default;
$large-screen-up: 1201px !default;
$small-screen: 600px !default;
$medium-screen: 992px !default;
$large-screen: 1200px !default;

$medium-and-up: "only screen and (min-width : #{$small-screen-up})" !default;
$large-and-up: "only screen and (min-width : #{$medium-screen-up})" !default;
$extra-large-and-up: "only screen and (min-width : #{$large-screen-up})" !default;
$small-and-down: "only screen and (max-width : #{$small-screen})" !default;
$medium-and-down: "only screen and (max-width : #{$medium-screen})" !default;
$medium-only: "only screen and (min-width : #{$small-screen-up}) and (max-width : #{$medium-screen})" !default;

// 12. Grid
// ==========================================================================

$num-cols: 12 !default;
$gutter-width: 1.5rem !default;
$element-top-margin: $gutter-width/3 !default;
$element-bottom-margin: ($gutter-width * 2)/3 !default;
.container {
  margin: 0 auto;
  max-width: 1280px;
  width: 90%;
}
@media #{$medium-and-up} {
  .container {
    width: 85%;
  }
}
@media #{$large-and-up} {
  .container {
    width: 70%;
  }
}
.col .row {
  margin-left: (-1 * $gutter-width / 2);
  margin-right: (-1 * $gutter-width / 2);
}

.section {
  padding-top: 1rem;
  padding-bottom: 1rem;

  &.no-pad {
    padding: 0;
  }
  &.no-pad-bot {
    padding-bottom: 0;
  }
  &.no-pad-top {
    padding-top: 0;
  }
}

// Mixins to eliminate code repitition
@mixin reset-offset {
  margin-left: auto;
  left: auto;
  right: auto;
}
@mixin grid-classes($size, $i, $perc) {
  &.offset-#{$size}#{$i} {
    margin-left: $perc;
  }
  &.pull-#{$size}#{$i} {
    right: $perc;
  }
  &.push-#{$size}#{$i} {
    left: $perc;
  }
}

.row {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    padding: 0 $gutter-width / 2;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
        @include reset-offset;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      @include grid-classes("s", $i, $perc);
      $i: $i + 1;
    }

    @media #{$medium-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("m", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$large-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("l", $i, $perc);
        $i: $i + 1;
      }
    }

    @media #{$extra-large-and-up} {
      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.xl#{$i} {
          width: $perc;
          @include reset-offset;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        @include grid-classes("xl", $i, $perc);
        $i: $i + 1;
      }
    }
  }
}
