.mm0 {
  margin: 0 !important;
}

.mm1 {
  margin: 0.25rem !important;
}

.mm2 {
  margin: 0.5rem !important;
}

.mm3 {
  margin: 1rem !important;
}

.mm4 {
  margin: 1.25rem !important;
}

.mm5 {
  margin: 1.5rem !important;
}

.mt0 {
  margin-top: 0 !important;
}

.mt1 {
  margin-top: 0.25rem !important;
}

.mt2 {
  margin-top: 0.5rem !important;
}

.mt3 {
  margin-top: 1rem !important;
}

.mt4 {
  margin-top: 1.25rem !important;
}

.mt5 {
  margin-top: 1.5rem !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.mb1 {
  margin-bottom: 0.25rem !important;
}

.mb2 {
  margin-bottom: 0.5rem !important;
}

.mb3 {
  margin-bottom: 1rem !important;
}

.mb4 {
  margin-bottom: 1.25rem !important;
}

.mb5 {
  margin-bottom: 1.5rem !important;
}

.ml0 {
  margin-left: 0 !important;
}

.ml1 {
  margin-left: 0.25rem !important;
}

.ml2 {
  margin-left: 0.5rem !important;
}

.ml3 {
  margin-left: 1rem !important;
}

.ml4 {
  margin-left: 1.25rem !important;
}

.ml5 {
  margin-left: 1.5rem !important;
}

.mr0 {
  margin-right: 0 !important;
}

.mr1 {
  margin-right: 0.25rem !important;
}

.mr2 {
  margin-right: 0.5rem !important;
}

.mr3 {
  margin-right: 1rem !important;
}

.mr4 {
  margin-right: 1.25rem !important;
}

.mr5 {
  margin-right: 1.5rem !important;
}

.p0 {
  padding: 0 !important;
}

.p1 {
  padding: 0.25rem !important;
}

.p2 {
  padding: 0.5rem !important;
}

.p3 {
  padding: 1rem !important;
}

.p4 {
  padding: 1.25rem !important;
}

.p5 {
  padding: 1.5rem !important;
}

.pt0 {
  padding-top: 0 !important;
}

.pt1 {
  padding-top: 0.25rem !important;
}

.pt2 {
  padding-top: 0.5rem !important;
}

.pt3 {
  padding-top: 1rem !important;
}

.pt4 {
  padding-top: 1.25rem !important;
}

.pt5 {
  padding-top: 1.5rem !important;
}

.pb0 {
  padding-bottom: 0 !important;
}

.pb1 {
  padding-bottom: 0.25rem !important;
}

.pb2 {
  padding-bottom: 0.5rem !important;
}

.pb3 {
  padding-bottom: 1rem !important;
}

.pb4 {
  padding-bottom: 1.25rem !important;
}

.pb5 {
  padding-bottom: 1.5rem !important;
}

.pl0 {
  padding-left: 0 !important;
}

.pl1 {
  padding-left: 0.25rem !important;
}

.pl2 {
  padding-left: 0.5rem !important;
}

.pl3 {
  padding-left: 1rem !important;
}

.pl4 {
  padding-left: 1.25rem !important;
}

.pl5 {
  padding-left: 1.5rem !important;
}

.pr0 {
  padding-right: 0 !important;
}

.pr1 {
  padding-right: 0.25rem !important;
}

.pr2 {
  padding-right: 0.5rem !important;
}

.pr3 {
  padding-right: 1rem !important;
}

.pr4 {
  padding-right: 1.25rem !important;
}

.pr5 {
  padding-right: 1.5rem !important;
}

.w100 {
  width: 100% !important;
}

.w90 {
  width: 90% !important;
}

.w80 {
  width: 80% !important;
}

.h80 {
  height: 80% !important;
  max-height: 80% !important;
}

.container-wide {
  width: 98%;
  margin: 0 auto;
}

.container-wide-toolbox {
  width: 98%;
  margin: 80px auto;
}

.container-toolbox {
  margin-top: 80px;
}

.d-flex {
  display: flex;
}

.d-flex-1 {
  flex: 1 1 auto;
}

.d-flex-0 {
  flex: 0 0 auto;
}

.d-align-center {
  align-items: center;
}

.spacer {
  flex: 1 1 auto;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-form-field {
  width: 100%;
}

.icon-form-field {
  display: flex;
}
.icon-form-field > mat-icon {
  font-size: 1.5rem;
  width: 2rem;
  padding-top: 1rem;
}

button i.left, button i.right {
  font-size: 16px;
}
button i.left, button mat-icon.left {
  margin-right: 8px;
  height: inherit;
  line-height: inherit;
}
button i.right, button mat-icon.right {
  margin-left: 8px;
  height: inherit;
  line-height: inherit;
}

a.mat-raised-button i.left, a.mat-raised-button mat-icon.left {
  font-size: 16px;
  margin-right: 8px;
  height: inherit;
  line-height: inherit;
}
a.mat-raised-button i.right, a.mat-raised-button mat-icon.right {
  font-size: 16px;
  margin-left: 8px;
  height: inherit;
  line-height: inherit;
}

.center-align {
  text-align: center;
}

.left-align {
  text-align: left;
}

.right-align {
  text-align: right;
  justify-content: flex-end;
}

.text-muted {
  color: rgba(0, 0, 0, 0.54);
}

.title {
  background: primary;
  font-size: medium;
}

.app-container {
  margin: 16px 24px 24px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  padding: 16px;
  background-color: #fff;
  min-height: calc(100% - 72px);
  border-radius: 4px;
}
.app-container.app-toolbox {
  margin-top: 80px;
  min-height: calc(100% - 136px);
}
@media (max-width: 599.9px) {
  .app-container {
    margin: 16px 8px 16px;
  }
  .app-container.app-toolbox {
    margin-top: 72px;
    min-height: calc(100% - 128px);
  }
}

.fullscreen-dialog .mat-dialog-container {
  max-width: none;
  width: 100vw;
  height: calc(100vh - 64px);
  padding: 0;
  margin-top: 64px;
  background-color: #F3F3F3;
}
.container-wide {
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
}
.container-toolbox {
  margin-top: 80px;
}

.modal-default-container {
  width: 500px;
}
@media (max-width: 599.9px) {
  .modal-default-container {
    width: 90vw;
    max-width: 90vw !important;
  }
}

.snack-done {
  background: green;
}

.snack-error {
  background: red;
}

.snack-warn {
  background: #fff3cd;
  color: #856404;
}

.danger {
  color: red;
}

.done {
  color: green;
}

.warn {
  color: yellow;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.fast {
  -webkit-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.fadeIn {
  animation-name: fadeIn;
}

.arrow-hand {
  cursor: pointer;
}

.mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-prefix .mat-icon-button, .mat-form-field:not(.mat-form-field-appearance-legacy) .mat-form-field-suffix .mat-icon-button {
  width: unset !important;
  height: unset !important;
}

.slide-toggle-container span {
  margin-left: 16px;
}

.table-container-overflow {
  overflow-x: auto;
}

input.no-spinner::-webkit-outer-spin-button,
input.no-spinner::-webkit-inner-spin-button {
  display: none;
}

input.no-spinner {
  -moz-appearance: textfield;
}

.mat-input-element.right-align {
  text-align: right;
}

.gray-icon-color {
  color: rgba(0, 0, 0, 0.54) !important;
}

.mat-icon-button:enabled.gray-icon {
  color: rgba(0, 0, 0, 0.54);
}

.mtx-popover-panel {
  padding: 0 !important;
}

mat-form-field.toolbox-input {
  font-size: 14px;
}
mat-form-field.toolbox-input .mat-form-field-wrapper {
  padding-bottom: 0;
}
mat-form-field.toolbox-input .mat-form-field-underline {
  bottom: 0;
}

.simple-onboard-message {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-weight: 400;
}

.list {
  margin: 4px;
  display: inline-flex;
  font-size: small;
}

.option-text {
  margin: 4px;
  display: inline-flex;
  padding: 4px 0px 4px 0px;
}

.right {
  margin-rigth: 8px;
}

.right-align {
  text-align: right;
}

.radio-group {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}

.radio-button {
  margin: 5px;
}

.col {
  padding: 4px;
  display: block;
  margin: 0px;
}

.chip {
  border-radius: 5px;
  text-align: center;
}

.bold {
  font-weight: 500;
  color: black;
}

.float {
  position: absolute;
  top: 16px;
  right: 28px;
}