$spacer: 1rem
//margins
.mm0
    margin: 0 !important
.mm1
    margin: ($spacer * .25) !important
.mm2
    margin: ($spacer * .5) !important
.mm3
    margin: $spacer !important
.mm4
    margin: ($spacer * 1.25) !important
.mm5
    margin: ($spacer * 1.5) !important
.mt0
    margin-top: 0 !important
.mt1
    margin-top: ($spacer * .25) !important
.mt2
    margin-top: ($spacer * .5) !important
.mt3
    margin-top: $spacer !important
.mt4
    margin-top: ($spacer * 1.25) !important
.mt5
    margin-top: ($spacer * 1.5) !important
.mb0
    margin-bottom: 0 !important
.mb1
    margin-bottom: ($spacer * .25) !important
.mb2
    margin-bottom: ($spacer * .5) !important
.mb3
    margin-bottom: $spacer !important
.mb4
    margin-bottom: ($spacer * 1.25) !important
.mb5
    margin-bottom: ($spacer * 1.5) !important

.ml0
    margin-left: 0 !important
.ml1
    margin-left: ($spacer * .25) !important
.ml2
    margin-left: ($spacer * .5) !important
.ml3
    margin-left: $spacer !important
.ml4
    margin-left: ($spacer * 1.25) !important
.ml5
    margin-left: ($spacer * 1.5) !important

.mr0
    margin-right: 0 !important
.mr1
    margin-right: ($spacer * .25) !important
.mr2
    margin-right: ($spacer * .5) !important
.mr3
    margin-right: $spacer !important
.mr4
    margin-right: ($spacer * 1.25) !important
.mr5
    margin-right: ($spacer * 1.5) !important
    
// paddings
.p0
    padding: 0 !important
.p1
    padding: ($spacer * .25) !important
.p2
    padding: ($spacer * .5) !important
.p3
    padding: $spacer !important
.p4
    padding: ($spacer * 1.25) !important
.p5
    padding: ($spacer * 1.5) !important
.pt0
    padding-top: 0 !important
.pt1
    padding-top: ($spacer * .25) !important
.pt2
    padding-top: ($spacer * .5) !important
.pt3
    padding-top: $spacer !important
.pt4
    padding-top: ($spacer * 1.25) !important
.pt5
    padding-top: ($spacer * 1.5) !important
.pb0
    padding-bottom: 0 !important
.pb1
    padding-bottom: ($spacer * .25) !important
.pb2
    padding-bottom: ($spacer * .5) !important
.pb3
    padding-bottom: $spacer !important
.pb4
    padding-bottom: ($spacer * 1.25) !important
.pb5
    padding-bottom: ($spacer * 1.5) !important

.pl0
    padding-left: 0 !important
.pl1
    padding-left: ($spacer * .25) !important
.pl2
    padding-left: ($spacer * .5) !important
.pl3
    padding-left: $spacer !important
.pl4
    padding-left: ($spacer * 1.25) !important
.pl5
    padding-left: ($spacer * 1.5) !important

.pr0
    padding-right: 0 !important
.pr1
    padding-right: ($spacer * .25) !important
.pr2
    padding-right: ($spacer * .5) !important
.pr3
    padding-right: $spacer !important
.pr4
    padding-right: ($spacer * 1.25) !important
.pr5
    padding-right: ($spacer * 1.5) !important
    
// widths %
.w100
    width: 100% !important
.w90
    width: 90% !important
.w80
    width: 80% !important
.h80
    height: 80% !important
    max-height: 80% !important
// containers
.container-wide
    width: 98%
    margin: 0 auto
.container-wide-toolbox
    width: 98%
    margin: 80px auto

.container-toolbox
    margin-top: 80px

.d-flex
    display: flex
.d-flex-1
    flex: 1 1 auto
.d-flex-0
    flex: 0 0 auto
    
.d-align-center
    align-items: center
    
.spacer
    flex: 1 1 auto